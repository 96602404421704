import React, { useEffect, useRef, useState } from 'react';
import SilentAudio from './SilientAudio';
import WakeLock from './WakeLock';

export interface Task {
  task: string;
  done: boolean;
  reason: string;
}

export interface EvaluationResponse {
  tasks: Task[];
  summary?: string;
}


interface AudioStreamerProps {
  onInterimTranscript: (text: string) => void;
  onFinalTranscript: (text: string) => void;
  onEvaluation: (evaluation: EvaluationResponse, isFinal: boolean, summary?: string) => void;
  onStart: () => void;
  onStop: () => void;
  websocketUrl: string;
}

const AudioStreamer: React.FC<AudioStreamerProps> = ({
  onInterimTranscript,
  onFinalTranscript,
  onEvaluation,
  onStart,
  onStop,
  websocketUrl
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const websocketRef = useRef<WebSocket | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);


  const startRecording = async () => {
    console.log('Starting recording process...');
    onStart();

    // Close existing WebSocket if it exists
    if (websocketRef.current) {
      console.log('Closing existing WebSocket connection...');
      websocketRef.current.close();
      websocketRef.current = null;
    }

    try {
      console.log('Requesting microphone access...');
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Microphone access granted');

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      console.log('MediaRecorder initialized');

      // Setup new WebSocket
      console.log('Setting up new WebSocket connection...');
      const ws = new WebSocket(websocketUrl);
      websocketRef.current = ws;

      ws.onopen = () => {
        console.log('WebSocket connection established');
        // Send start recording event
        ws.send(JSON.stringify({ type: 'start_recording' }));
      };

      ws.onclose = () => {
        console.warn('WebSocket connection closed');
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.debug('Received WebSocket message:', data);

          if (data.type === 'transcript_interim') {
            console.log('Received interim transcript:', data.text);
            onInterimTranscript(data.text);
          } else if (data.type === 'transcript_final') {
            console.log('Received final transcript:', data.text);
            onFinalTranscript(data.text);
          } else if (data.type === 'evaluation') {
            console.log('Received evaluation:', data.data);
            onEvaluation(data.data, false);
          } else if (data.type === 'evaluation_final') {
            console.log('Received final evaluation:', data.data);
            onEvaluation(data.data, true, data.summary);
          } else if (data.type === 'error') {
            console.error('Received error from server:', data.message);
          }
        } catch (error) {
          console.error('Error processing WebSocket message:', error);
        }
      };

      // Send audio data through WebSocket
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0 && ws.readyState === WebSocket.OPEN) {
          console.debug(`Sending audio chunk of size: ${event.data.size} bytes`);
          ws.send(event.data);
        }
      };

      mediaRecorder.onstart = () => {
        console.log('MediaRecorder started');
      };

      mediaRecorder.onstop = () => {
        console.log('MediaRecorder stopped');
      };

      mediaRecorder.onerror = (error) => {
        console.error('MediaRecorder error:', error);
      };

      mediaRecorder.start(250); // Collect data every 250ms
      setIsRecording(true);
      console.log('Recording started successfully');
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    console.log('Stopping recording...');
    onStop();
    if (mediaRecorderRef.current) {
      console.log('Stopping MediaRecorder');
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach(track => {
        console.log('Stopping audio track:', track.label);
        track.stop();
      });
    }
    if (websocketRef.current) {
      // Send stop recording event but don't close the connection
      websocketRef.current.send(JSON.stringify({ type: 'stop_recording' }));
    }
    setIsRecording(false);
    console.log('Recording stopped successfully');
  };

  useEffect(() => {
    return () => {
      console.log('Component cleanup');
      if (isRecording) {
        console.log('Stopping recording during cleanup');
        stopRecording();
      }
    };
  }, []);

  return (
    <div>
      <SilentAudio isPlaying={isRecording} testMode={false} />
      <WakeLock isActive={isRecording} />
      <button
        onClick={isRecording ? stopRecording : startRecording}
        className={`
          px-6 py-3 rounded-lg font-medium text-white
          transition-all duration-200 flex items-center gap-2
          ${isRecording
            ? 'bg-red-500 hover:bg-red-600'
            : 'bg-blue-500 hover:bg-blue-600'
          }
        `}
      >
        <div className={`
          w-3 h-3 rounded-full
          ${isRecording
            ? 'bg-white animate-pulse'
            : 'bg-white'
          }
        `} />
        {isRecording ? 'Stop Recording' : 'Start Recording'}
      </button>
    </div>
  );
};

export default AudioStreamer;
