import React, { useState, useEffect } from 'react';
import * as Sentry from "@sentry/react";
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Login from './components/Login';
import AuthCallback from './components/AuthCallback';
import api from './api';
import Main from './components/Main';
import Communications from './components/Communications';
import AuthOutlet from './components/AuthOutlet';
import useViewportHeight from './hooks/useViewportHeight';
import FullPageSpinner from './components/FullPageSpinner';
import EditCommunication from './components/EditCommunication';
import AudioTest from './components/AudioDeviceDemo';
import Logout from './components/Logout';
import EditUseCasePage from './components/admin/EditUseCasePage';
import User from './models/User';
import Requests from './components/Requests';
import InterviewLoader from './components/InterviewLoader';
import { UserProvider } from './hooks/useUserContext';
import ErrorFallback from './components/ErrorFallback';
import UserAdmin from './components/admin/UserAdmin';
import CommunicationAdmin from './components/admin/CommunicationAdmin';
import CommunicationDetail from './components/admin/CommunicationDetail';
import UseCaseAdmin from './components/admin/UseCaseAdmin';
import { hotjar } from 'react-hotjar';
import ScrollToTop from './components/ScrollToTop';
import InterviewUI, { InterviewUIState } from './components/InterviewUI';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainFeedback from './components/MainFeedback';
import AudioDeviceDemo from './components/AudioDeviceDemo';
import RestaurantDashboard from './components/restaurant/RestaurantDashboard';
import RestaurantAudio from './components/restaurant/RestaurantAudio';

const DUMMY_USER: User = {
  id: '1',
  email: 'test@test.com',
  isSuperuser: false,
  hasSeenVideo: true,
  useOwnVoice: false
}

const App: React.FC = () => {
  useViewportHeight(); // This handles setting the --vh property which enables h-screen-dynamic to work (see tailwing.config.js)
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    console.log('Current path:', location.pathname);
  }, [location]);


  const setAndStoreToken = (token: string | null) => {
    if (token) {
      localStorage.setItem('token', token);
      setToken(token);
      fetchUserData();
    } else {
      localStorage.removeItem('token');
      setToken(null);
    }
  }

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user) {
      Sentry.setUser({ email: user.email });
      if (hotjar.initialized()) {
        hotjar.identify('USER_ID', { email: user.email });
      }
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  useEffect(() => {
    if (token) {
      fetchUserData();
    } else {
      setUser(null);
    }
  }, [token]);

  const fetchUserData = async (): Promise<void> => {
    setLoading(true);
    try {
      const userData: User = await api.fetchUserData();
      setUser(userData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      // You might want to set an error state here and display it to the user
      setLoading(false);
      setUser(null);
      setAndStoreToken(null);
    }
  };

  const handleLogout = (): void => {
    localStorage.removeItem('token');
    setToken(null);
    setUser(null);
  };
  if (loading) {
    return <FullPageSpinner />
  }

  return (
    <UserProvider initialUser={user || DUMMY_USER}>
      <ToastContainer />
      <ScrollToTop />
      <Routes>
        <Route path="/ui_test" element={<InterviewUI
          onClose={() => { }} title={''}
          state={InterviewUIState.LISTENING}
          onSkipSpeaking={() => { }}
          onStopRecording={() => { }}
          isAirPlayAvailable={false}
          showAirPlayPicker={() => { }}
          togglePauseReading={() => { }}
          onBack={() => { }}
          processingText={"processing"} />
        } />
        <Route path="/audio_device_demo" element={<AudioDeviceDemo />} />
        <Route path="/auth/google/callback" element={<AuthCallback setToken={setAndStoreToken} />} />
        <Route path="/login" element={user ? <Navigate to="/" replace /> : <Login />} />
        <Route path="/logout" element={<Logout handleLogout={handleLogout} />} />
        <Route path="/error" element={<ErrorFallback />} />
        <Route path="/restaurant" element={<RestaurantDashboard />} />
        <Route path="/restaurant_audio" element={<RestaurantAudio />} />

        {/* Admin Routes */}
        <Route element={<AuthOutlet isAuthenticated={user != null && user.isSuperuser} fallbackPath="/login" />} >
          <Route path="/admin/use-case" element={<UseCaseAdmin />} />
          <Route path="/admin/use-case/new" element={<EditUseCasePage />} />
          <Route path="/admin/use-case/:id" element={<EditUseCasePage />} />
          <Route path="/admin/user" element={<UserAdmin />} />
          <Route path="/admin/communication" element={<CommunicationAdmin />} />
          <Route path="/admin/communication/:id" element={<CommunicationDetail />} />
        </Route>

        <Route element={<AuthOutlet isAuthenticated={user != null} fallbackPath="/login" />} >
          <Route path="/oldmain" element={<Main />} />
          <Route path="/" element={<MainFeedback />} />
          <Route path="/interview" element={<InterviewLoader />} />
          <Route path="/communication" element={<Communications />} />
          <Route path="/request" element={<Requests />} />
          <Route path="/communication/:communicationId" element={<EditCommunication />} />
        </Route>
      </Routes >
    </UserProvider>
  );
}

export default App;