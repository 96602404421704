import { useEffect, useRef } from 'react';

interface SilentAudioProps {
  isPlaying: boolean;
  testMode?: boolean;
}

const SilentAudio: React.FC<SilentAudioProps> = ({ isPlaying, testMode = false }) => {
  const audioContextRef = useRef<AudioContext | null>(null);
  const silentAudioRef = useRef<AudioBufferSourceNode | null>(null);

  useEffect(() => {
    if (isPlaying) {
      if (!audioContextRef.current) {
        audioContextRef.current = new AudioContext();
      }

      const sampleRate = audioContextRef.current.sampleRate;
      const buffer = audioContextRef.current.createBuffer(1, sampleRate, sampleRate);
      const output = buffer.getChannelData(0);

      // Generate either silence or a test tone
      for (let i = 0; i < sampleRate; i++) {
        if (testMode) {
          // Generate a 440Hz sine wave (A4 note)
          output[i] = Math.sin(2 * Math.PI * 440 * i / sampleRate) * 0.1;
        } else {
          output[i] = 0; // Silent audio data
        }
      }

      // Create and configure source node
      const source = audioContextRef.current.createBufferSource();
      source.buffer = buffer;
      source.loop = true;
      source.connect(audioContextRef.current.destination);
      source.start();
      silentAudioRef.current = source;
    }

    return () => {
      if (silentAudioRef.current) {
        silentAudioRef.current.stop();
        silentAudioRef.current = null;
      }
      if (!isPlaying && audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
      }
    };
  }, [isPlaying]);

  return null;
};

export default SilentAudio;